import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MuiAlert from '@material-ui/lab/Alert';
import handleResponse, {errorToString} from './HttpUtils'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();

  const [gender, setGender] = React.useState('');
  const [pushover, setPushover] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [relationship, setRelationship] = React.useState('');
  const [boundaries, setBoundaries] = React.useState('');
  const [desires, setDesires] = React.useState('');
  const [safe, setSafe] = React.useState('');
  const [meaning, setMeaning] = React.useState('');
  const [snapchat, setSnapchat] = React.useState('');
  const [kik, setKik] = React.useState('');
  const [facebook, setFacebook] = React.useState('');
  const [instagram, setInstagram] = React.useState('');
  const [darkside, setDarkside] = React.useState('');
  const [chaturbate, setChaturbate] = React.useState('');
  const [webpage, setWebpage] = React.useState('');
  const [error, setError] = React.useState('')
  const [msg, setMsg] = React.useState('')
  const [selectedFile, setSelectedFile] = React.useState();
  const [fileInputKey, setFileInputKey] = React.useState('A');
  const [profile_picture, setProfile_picture] = React.useState('')

  useEffect(() => {
    setGender(props.user.genitalia.toString())
    setSnapchat(props.user.social_snapchat)
    setKik(props.user.social_kik)
    setFacebook(props.user.social_facebook)
    setInstagram(props.user.social_instagram)
    setDarkside(props.user.social_darkside)
    setChaturbate(props.user.social_chaturbate)
    setWebpage(props.user.social_webpage)
    setDescription(props.user.description)
    setPushover(props.user.pushover)
    setRelationship(props.user.relationship_status)
    setBoundaries(props.user.boundary_status)
    setDesires(props.user.desire_status)
    setSafe(props.user.sexual_status)
    setMeaning(props.user.meaning_status)
    //setProfile_picture(props.user.profile_picture)
  }, [])

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const fileSelectHandler = (event) => {
  setProfile_picture(event.target.files[0]);
  };

  const signUpButtonHandler = (e) => {
    e.preventDefault()

    const formData = new FormData();
    if(profile_picture){
        formData.append('file', profile_picture);
    }
    formData.append('genitalia', gender);
    formData.append('pushover', pushover);
    formData.append('snapchat', snapchat);
    formData.append('kik', kik);
    formData.append('facebook', facebook);
    formData.append('instagram', instagram);
    formData.append('darkside', darkside);
    formData.append('chaturbate', chaturbate);
    formData.append('webpage', webpage);
    formData.append('description', description);
    formData.append('relationship', relationship);
    formData.append('boundaries', boundaries);
    formData.append('desires', desires);
    formData.append('sexual', safe);
    formData.append('meaning', meaning);

    const requestOptions = {
      method: 'post',
      body: formData,
    };
    // actually saving the new profile image
    fetch(`/api/users/${props.user.id}`, requestOptions)
      .then(handleResponse)
      .then(data => {props.refreshUser()})
      .then(data => {setMsg('Saved')})
      .then(data => {setError(null)})
      .catch(error => {setError(errorToString(error))})
    return
  }

  var gravatar_url = ""
  if (props.user.profile_picture == null){
    var gravatar_url = `https://www.gravatar.com/avatar/${props.user.gravatar}?r=x`
  } else {
    var gravatar_url = `/static/profile_pictures/${props.user.profile_picture}`
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar style={{width: '90px', height: '90px'}} src={gravatar_url} >{props.user.username}</Avatar>
        <Typography component="h1" variant="h5">
          {props.user.username}
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>



            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                multiline
                rows={4}
                fullWidth
                id="description"
                label="Description"
                name="description"
                value={description}
                onInput={e => setDescription(e.target.value)}
              />
            </Grid>
 <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                id="relationship"
                label="Relationship"
                name="relationship"
                value={relationship}
                onInput={e => setRelationship(e.target.value)}
              />
              </Grid>
 <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                id="boundaries"
                label="Boundaries"
                name="boundaries"
                value={boundaries}
                onInput={e => setBoundaries(e.target.value)}
              />
            </Grid>
 <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                id="desires"
                label="Desires"
                name="desires"
                value={desires}
                onInput={e => setDesires(e.target.value)}
              />
            </Grid>
 <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                id="safe"
                label="Sexual"
                name="safe"
                value={safe}
                onInput={e => setSafe(e.target.value)}
              />
            </Grid>
  <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                id="meaning"
                label="Meaning"
                name="meaning"
                value={meaning}
                onInput={e => setMeaning(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="snapchat"
                label="Snapchat"
                name="snapchat"
                value={snapchat}
                onInput={e => setSnapchat(e.target.value)}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="pushover"
                label="Pushover"
                name="pushover"
                value={pushover}
                onInput={e => setPushover(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="kik"
                label="Kik"
                name="kik"
                value={kik}
                onInput={e => setKik(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="facebook"
                label="Facebook"
                name="facebook"
                value={facebook}
                onInput={e => setFacebook(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="instagram"
                label="Instagram"
                name="instagram"
                value={instagram}
                onInput={e => setInstagram(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="darkside"
                label="Darkside"
                name="darkside"
                value={darkside}
                onInput={e => setDarkside(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="chaturbate"
                label="Chaturbate"
                name="chaturbate"
                value={chaturbate}
                onInput={e => setChaturbate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="webpage"
                label="Webpage"
                name="webpage"
                value={webpage}
                onInput={e => setWebpage(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">I have a:</FormLabel>
                <RadioGroup aria-label="gender" name="gender" value={gender} onChange={handleChange}>
                  <FormControlLabel value="2" control={<Radio />} label="Pussy" />
                  <FormControlLabel value="1" control={<Radio />} label="Cock" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <FormLabel component="legend">Profile Picture:&nbsp;</FormLabel>
            <input type="file" name="profile_picture" key={fileInputKey || ''} onChange={fileSelectHandler} />
          </Grid>
          {error ? <MuiAlert  variant="outlined" severity="error">{error}</MuiAlert> : <></>}
          {msg ? <MuiAlert  variant="outlined" severity="info">{msg}</MuiAlert> : <></>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={signUpButtonHandler}
          >
            Save
          </Button>
        </form>
      </div>
    </Container>
  );
}
