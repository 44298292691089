import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { IconButton } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import PersonIcon from '@material-ui/icons/Person';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import handleResponse, {errorToString} from './HttpUtils'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
  root: {
  },
  activeCard: {
    width: 300,
  },
  pendingCard:{
    width: 300,
    backgroundColor: "#f0f0f0"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial'
  },
  pos: {
    marginBottom: 12,
  },
  specGridItem: {
    marginRight: "10px",
    marginBottom: "10px"
  }
});


export default function Challenges(props){
    const classes = useStyles();
    const [pendingChallenges, setPendingChallenges] = React.useState([]);
    const [acceptedChallenges, setAcceptedChallenges] = React.useState([]);
    const [users, setUsers] = React.useState({});

    useEffect(() => {
      refreshUsers()
      .then(() => refreshCards())
    }, [])

    const refreshUsers = () => {
      return fetch('/api/users')
        .then(handleResponse)
        .then(data => setUserMapData(data));
    }

    const setUserMapData = (users) => {
        const userMap = (users.reduce(function(map, obj) {
            map[obj.id] = obj
            return map;
        }, {}))
        setUsers(userMap)
    }

    const refreshCards = () => {
        return refreshAcceptedCards()
        .then(data => refreshPendingCards())
    }

    const refreshAcceptedCards = () => {
      return fetch(`/api/challenges?state=accepted&recipient=${props.user.id}&game_id=${props.user.active_game}`)
        .then(handleResponse)
        .then(data => setAcceptedChallenges(data));
    }

    const refreshPendingCards = () => {
      return fetch(`/api/challenges?state=pending&recipient=${props.user.id}&game_id=${props.user.active_game}`)
        .then(handleResponse)
        .then(data => setPendingChallenges(data));
    }
  return (
    <div>

      <Typography className={classes.pos} color="textSecondary">
        CONSENTED CHALLENGES
            </Typography>

      <Grid container>

        {acceptedChallenges.map(v => (
            <Grid item className={classes.specGridItem} key={v.id}>
              <ChallengeCard challenge={v} user={props.user} users={users} refreshUser={props.refreshUser} refreshCardsHandler={refreshCards}></ChallengeCard>
            </Grid>
        ))}
      </Grid>

      <Typography className={classes.pos} color="textSecondary">
        PENDING CHALLANGES
      </Typography>

      <Grid container>
        {pendingChallenges.map(v => (
            <Grid item className={classes.specGridItem} key={v.id}>
              <ChallengeCard challenge={v} user={props.user} users={users} refreshUser={props.refreshUser} refreshCardsHandler={refreshCards}></ChallengeCard>
            </Grid>
        ))}
      </Grid>
    </div>
  )

}
function ChallengeCard(props){
  const classes = useStyles();

  const hasConsented = props.challenge.recipients.find(o => o.id==props.user.id && o.consented==true) != undefined
  const hasConfirmed = props.challenge.recipients.find(o => o.id==props.user.id && o.confirmed==true) != undefined
  const [hidden, setHidden] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const PendingButtons = () => (
    <>
        <Button variant="contained" color="primary" onClick={(e) => ConsentButtonHandler(props.challenge)}> I consent </Button>
        <Button variant="contained" color="secondary" onClick={(e) => DeclineButtonHandler(props.challenge)}> I decline </Button>
    </>
  )

  const AcceptedButtons = (props) => (
    <>
        <FormDialog
          challenge={props.challenge}
          open={dialogOpen}
          setOpen={setDialogOpen} 
          challengeComplete={ChallengeCompletedButtonHandler}
          user={props.user}
         ></FormDialog>
        <Button variant="contained" color="primary"
        onClick={(e) => setDialogOpen(true)}
        > Complete Challenge</Button>
    </>
  )

  const ChallengeCompletedButtonHandler = (challenge) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api/challenges/' + props.challenge.id + '/complete', requestOptions)
      .then(handleResponse)
      // .then(data => props.refreshCardsHandler())
      .then(data => setHidden(true))
      .catch((error) => { alert(errorToString(error))})
  }

  const ConsentButtonHandler = (challenge) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api/challenges/' + props.challenge.id + '/consent', requestOptions)
      .then(handleResponse)
      .then(data => setHidden(true))
      .then(data => props.refreshCardsHandler())
      .catch((error) => { alert(errorToString(error))})
  }

  const DeclineButtonHandler = (challenge) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api/challenges/' + props.challenge.id + '/decline', requestOptions)
      .then(handleResponse)
      // .then(data => props.refreshCardsHandler())
      .then(data => setHidden(true))
      .catch((error) => { alert(errorToString(error))})
  }

  if(hasConfirmed){
    return (<></>)
  }

  const stateClass = props.challenge.state == 'PENDING' ? classes.pendingCard : classes.activeCard

  return (

<Grow in={!hidden} unmountOnExit >
    <Paper elevation={3} className={classes.card} className={props.class}>
    <Card className={stateClass} variant="outlined" id={`ch-${props.challenge.id}`}>

      <CardHeader
        title={ <Typography variant="h6"> {props.challenge.title} </Typography>}
        subheader={<Typography variant='body2'>{props.challenge.description_out}</Typography>}
      />

        <CardActions>
        <UserAvatar key={`owner_${props.challenge.owner_id}`} user={props.users[props.challenge.owner_id]} ></UserAvatar>
        <ArrowForwardIosIcon />
        {
          props.challenge.recipients.map((i) => (
             <UserAvatar key={i.id} user={props.users[i.id]} ></UserAvatar>
          ))
        }
      </CardActions>

      <CardActions>

        <Typography paragraph>
          <Chip
            className={classes.cChip}
            label={props.challenge.category.title}
            style={{ background: props.challenge.color }}
          />
          <Chip label={props.users[props.challenge.owner_id].username} />
          {
            props.challenge.recipients.map((i) => (
              <Chip key={i.id} label={props.users[i.id].username} />
            ))
          }

        </Typography>
      </CardActions>

      <CardActions>
        {
          props.challenge.state == 'PENDING' && !hasConsented ?
            <PendingButtons challenge={props.challenge}></PendingButtons> :
            props.challenge.state == 'ACCEPTED' ?
            <AcceptedButtons challenge={props.challenge} user={props.user}></AcceptedButtons> :
            <Button variant="contained" disabled="true">Waiting for consent from others</Button>
        }
      </CardActions>
    </Card>
</Paper>
</Grow>
  );
}

function UserAvatar(props) {
  const classes = useStyles();


  return(
    <IconButton key={props.user.id} >
      <Avatar src={props.user.gravatar} >{props.user.username}</Avatar>
    </IconButton>
  )
}



 function FormDialog(props) {
  const open = props.open
  const setOpen = props.setOpen
  const [msg, setMsg] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [fileInputKey, setFileInputKey] = React.useState('A');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSave = () => {
    sendMessage()
    setSelectedFile(null)
    setFileInputKey(Math.random().toString(36))
    props.challengeComplete(props.challenge)
    setOpen(false);
  }

  async function sendMessage(){
     if (!selectedFile && !msg.length > 0) {
       return
     }

     const formData = new FormData();
     if (selectedFile) {
       formData.append('file', selectedFile);
     }
     formData.append('body', `---CHALLENGE--- \n ${props.challenge.title} \n ${props.challenge.description_out}: \n\n ${msg}`);
     formData.append('recipient', props.challenge.owner_id);
     formData.append('author', props.user.id);

     const requestOptions = {
       method: 'post',
       body: formData,
     }
     await fetch('/api/messages', requestOptions)
       .then(handleResponse)
       .catch((error) => { alert(errorToString(error)) })
   }


   const handleFileSelected = (event) => {
		setSelectedFile(event.target.files[0]);
	};
   const handleMsgChange = (event) => {
    setMsg(event.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.challenge.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.challenge.description_out}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            label="text"
            type="text"
            fullWidth
            value={msg} onChange={handleMsgChange}
            multiline
            rows={4}
          />
          <input type="file" name="file" key={props.fileInputKey || ''} onChange={handleFileSelected} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="seconday">
            Complete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}