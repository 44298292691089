import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import handleResponse from './HttpUtils'
import MuiAlert from '@material-ui/lab/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();

  const [gender, setGender] = React.useState('pussy');
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [pushover, setPushover] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [error, setError] = React.useState('')

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const signUpButtonHandler = (e) => {
    e.preventDefault()

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        {
          'email': email,
          'password': password,
          'username': username,
          'gender': gender,
          'pushover': pushover,
          'description': description
        })
    };
    fetch('/api/users', requestOptions)
      .then(handleResponse)
      .then(data => props.setIsRegistrationView(false))
      .catch(error => {setError(error.json.message)})
    return
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="username"
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Username"
                autoFocus
                onInput={e => setUsername(e.target.value)}
              />
            </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onInput={e => setPassword(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onInput={e => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                onInput={e => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">I have a:</FormLabel>
                <RadioGroup aria-label="gender" name="gender" value={gender} onChange={handleChange}>
                  <FormControlLabel value="2" control={<Radio />} label="Pussy" />
                  <FormControlLabel value="1" control={<Radio />} label="Cock" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
            <h2>About PervLife</h2>
            <p class="lead">This is a social decadent game exploring open sexuality and play.
            </p>
            <p><ul>
              <li>Nudity is natural</li>
              <li>Safety is sexy</li>
              <li>Consent is sacred</li>
              <li>Boundaries are beautiful</li>
              <li>No is a complete sentence</li>
              <li>And as always: No surprise penetration</li>
            </ul></p>
            <h3>Game etiquette</h3>
            <ul>
              <li><b>Make a personal profile</b> - Set a descriptive name, make sure you have a
              gravatar or an uploaded picture. <b>Only one person per account, no couple profiles.</b></li>
              <li><b>Start slow</b> - Start by sending safe challenges, you never know where
              the other person is at.</li>
              <li><b>Decline, decline, decline</b> - A maybe is a no, you get points for
                declining a challenge so feel very free to decline any and all challenges.</li>
              <li><b>Change your mind</b> - It is ok to change your mind, take back a challenge, and
                change your mind even if you already said yes. It is also ok to change your mind
                and say yes even if you said no before.</li>
              <li><b>Respond quickly</b> - Given that someone is on the other side waiting it is better to
              decline than think about it for a long time. If you consented and it's dragging out on time you
              can always change your mind and take back your consent. Likewise you can always take back your request.</li>
              <li><b>Where to respond</b> - If you respond outside of this app make sure you
                it is ok to do so and ask where the recipient wants you to respond.</li>
              <li><b>Affirm, affirm, affirm</b> - When someone sends you a picture or video
              respond with hearts and affirmation (not flirtation).</li>
              <li><b>It's not more</b> - This is a game, always assume there
              is no deeper meaning to the requests and completed challenges unless stated otherwise.</li>
              <li><b>Not an invitation</b> - Getting a hot picture or video is not an invitation
              to flirt or converse. If you want to engage in further conversation and/or flirt,
              ask first if it is ok to do so.</li>
              <li><b>Be safe</b> - If you carry out challenges in public, take extra care that you are safe.</li>
              <li><b>Practice safer sex</b> - If you carry out IRL challenges make sure to
              play safely, sanely and consentually.</li>
              <li><b>Context is crucial</b> - If you respond outside this app first let the
                recipient know that you are responding to a challenge and also which one.</li>
              <li><b>Quid pro quo</b> - Only send challenges you would be willing to take on yourself.</li>

            </ul>
            </Grid>
          </Grid>
          {error ? <MuiAlert  variant="outlined" severity="error">{error}</MuiAlert> : <></>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={signUpButtonHandler}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="#" onClick={e => props.setIsRegistrationView(false)} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
