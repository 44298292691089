import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import handleResponse, {errorToString} from './HttpUtils'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    width: 300,
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function Pervlife(props){
  const classes = useStyles();
  const [expandedAbout, setExpandedAbout] = React.useState(false);
  const handleExpandAbout = () => {
    setExpandedAbout(!expandedAbout);
  };
  const [expandedRules, setExpandedRules] = React.useState(false);
  const handleExpandRules = () => {
    setExpandedRules(!expandedRules);
  };
  const [expandedSupport, setExpandedSupport] = React.useState(false);
  const handleExpandSupport = () => {
    setExpandedSupport(!expandedSupport);
  };
  return(

            <div className={classes.paper}>
            <Card className={classes.root}>
            <CardHeader title="About Pervlife" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              This is a social decadent game created by Patrik and Anna Olterman,
              exploring open sexuality and play.
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedAbout,
            })}
            onClick={handleExpandAbout}
            aria-expanded={expandedAbout}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedAbout} timeout="auto" unmountOnExit>
            <CardContent>
            <p><ul>
              <li>Nudity is natural</li>
              <li>Safety is sexy</li>
              <li>Consent is sacred</li>
              <li>Boundaries are beautiful</li>
              <li>No is a complete sentence</li>
              <li>And as always: No surprise penetration</li>
            </ul></p>
            </CardContent>
            </Collapse>
            </Card>
            <Card className={classes.root}>
            <CardHeader title="Game etiquette" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              To play the game well it is good to adhere to these simple ideas
              of how the game is played
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedRules,
            })}
            onClick={handleExpandRules}
            aria-expanded={expandedRules}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedRules} timeout="auto" unmountOnExit>
            <CardContent>
            <ul>
              <li><b>Make a personal profile</b> - Set a descriptive name, make sure you have a
              gravatar or an uploaded picture. Only one person per account, no couple profiles.</li>
              <li><b>Start slow</b> - Start by sending safe challenges, you never know where
              the other person is at.</li>
              <li><b>Decline, decline, decline</b> - A maybe is a no, you get points for
                declining a challenge so feel very free to decline any and all challenges.</li>
              <li><b>Change your mind</b> - It is ok to change your mind, take back a challenge, and
                change your mind even if you already said yes. It is also ok to change your mind
                and say yes even if you said no before.</li>
              <li><b>Respond quickly</b> - Given that someone is on the other side waiting it is better to
              decline than think about it for a long time. If you consented and it's dragging out on time you
              can always change your mind and take back your consent. Likewise you can always take back your request.</li>
              <li><b>Where to respond</b> - If you respond outside of this app make sure you
                it is ok to do so and ask where the recipient wants you to respond.</li>
              <li><b>Affirm, affirm, affirm</b> - When someone sends you a picture or video
              respond with hearts and affirmation (not flirtation).</li>
              <li><b>It's not more</b> - This is a game, always assume there
              is no deeper meaning to the requests and completed challenges unless stated otherwise.</li>
              <li><b>Not an invitation</b> - Getting a hot picture or video is not an invitation
              to flirt or converse. If you want to engage in further conversation and/or flirt,
              ask first if it is ok to do so.</li>
              <li><b>Be safe</b> - If you carry out challenges in public, take extra care that you are safe.</li>
              <li><b>Practice safer sex</b> - If you carry out IRL challenges make sure to
              play safely, sanely and consentually.</li>
              <li><b>Context is crucial</b> - If you respond outside this app first let the
                recipient know that you are responding to a challenge and also which one.</li>
              <li><b>Quid pro quo</b> - Only send challenges you would be willing to take on yourself.</li>

            </ul>
            </CardContent>
            </Collapse>
            </Card>
            <Card className={classes.root}>
            <CardHeader title="Support Pervlife" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              Be part of the support team and support the development financially
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedSupport,
            })}
            onClick={handleExpandSupport}
            aria-expanded={expandedSupport}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedSupport} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                If you like this project and want to support our work pls do so by <a href="https://www.buymeacoffee.com/olterman">Buying me a coffe!</a> Any
              money that comes in will be put towards securing a domain and webhosting for
              this site
              </Typography>
            </CardContent>
            </Collapse>
            </Card>
            </div>



  )
}
