import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { IconButton } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import { Paper } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import LockIcon from '@material-ui/icons/Lock';
import Chip from '@material-ui/core/Chip';
import PersonIcon from '@material-ui/icons/Person';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import handleResponse, {errorToString} from './HttpUtils'
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles({
  buttonGrid: {
    flexGrow: 1,
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial'
  },
  pos: {
    marginBottom: 12,
  },
  userSelected: {
    width: "60px",
    height: "60px",
    border: "2px solid #666"
  },
  user: {
    width: "60px",
    height: "60px",
  },
  card: {
    marginBottom: "5px",
    maxWidth: 400,
  },
  shopButton: {
    margin: "10px"
  },
  specGridItem: {
    marginRight: "10px",
    marginBottom: "10px"
  },
  activeCard: {
    width: 300,
  },
  pendingCard: {
    width: 300,
    backgroundColor: "#f0f0f0"
  },
  declinedCard: {
    width: 300,
    backgroundColor: "#ffcdd2"
  },


});


export default function Requests(props) {

  const classes = useStyles();
  const [pendingRequests, setPendingRequests] = React.useState([]);
  const [acceptedRequests, setAcceptedRequests] = React.useState([]);
  const [declinedRequests, setDeclinedRequests] = React.useState([]);
  const [users, setUsers] = React.useState({});
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    refreshUsers()
      .then(() => refreshCards())
      .then(() => setReady(true))
  }, [])

  const refreshUsers = () => {
    return fetch('/api/users')
      .then(handleResponse)
      .then(data => setUserMapData(data));
  }


  const setUserMapData = (users) => {
    const userMap = (users.reduce(function (map, obj) {
      map[obj.id] = obj
      return map;
    }, {}))
    setUsers(userMap)
  }

  const refreshCards = () => {
    return refreshAcceptedCards()
      .then(data => refreshPendingCards())
      .then(data => refreshDeclinedCards())
  }

  const refreshAcceptedCards = () => {
    return fetch(`/api/challenges?state=accepted&owner=${props.user.id}&game_id=${props.user.active_game}`)
      .then(handleResponse)
      .then(data => setAcceptedRequests(data));
  }

  const refreshPendingCards = () => {
    return fetch(`/api/challenges?state=pending&owner=${props.user.id}&game_id=${props.user.active_game}`)
      .then(handleResponse)
      .then(data => setPendingRequests(data));
  }

  const refreshDeclinedCards = () => {
    return fetch(`/api/challenges?state=declined&owner=${props.user.id}&game_id=${props.user.active_game}`)
      .then(handleResponse)
      .then(data => setDeclinedRequests(data));
  }

  if (!ready) {
    return (<div>Loading</div>)
  }
  return (
    <div>

      <Typography className={classes.pos} color="textSecondary">
        CONSENTED REQUESTS
            </Typography>
      <Grid container>
        {acceptedRequests.map(v => (
            <Grid item className={classes.specGridItem} key={v.id}>
              <RequestCard challenge={v} users={users} refreshUser={props.refreshUser} refreshCardsHandler={refreshCards}></RequestCard>
            </Grid>
        ))}
      </Grid>

      <Typography className={classes.pos} color="textSecondary">SENT REQUESTS</Typography>
      <Grid container>
        {pendingRequests.map(v => (
            <Grid item className={classes.specGridItem} key={v.id}>
              <RequestCard users={users} challenge={v} users={users} refreshUser={props.refreshUser} refreshCardsHandler={refreshCards}></RequestCard>
            </Grid>
        ))}
      </Grid>

      <Typography className={classes.pos} color="textSecondary"> DECLINED REQUESTS </Typography>
      <Grid container>
        {declinedRequests.map(v => (
            <Grid item className={classes.specGridItem} key={v.id}>
              <RequestCard  users={users} challenge={v} users={users} refreshUser={props.refreshUser} refreshCardsHandler={refreshCards}></RequestCard>
            </Grid>
        ))}
      </Grid>

    </div>
  )

}


function RequestCard(props) {

  const classes = useStyles();
  const [hidden, setHidden] = React.useState(false);

  const RemoveButtonHandler = (challenge) => {

    const requestOptions = {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api/challenges/' + props.challenge.id + '', requestOptions)
      .then(handleResponse)
      .then(data => setHidden(true))
      .catch((error) => { alert(errorToString(error))})
  }

  const RemoveButtons = (props) => (
    <>
      <Button variant="contained" onClick={(e) => RemoveButtonHandler(props.challenge)}> Remove </Button>
    </>
  )

  const stateClass = props.challenge.state == 'PENDING' ? classes.pendingCard : props.challenge.state == 'DECLINED' ? classes.declinedCard : classes.activeCard


  return (

<Grow in={!hidden} unmountOnExit >
    <Paper elevation={3} className={classes.card}>
      <Card className={stateClass} variant="outlined" id={`ch-${props.challenge.id}`} >

        <CardHeader
          title={<Typography variant="h6" >{props.challenge.title}</Typography>}
          subheader={<Typography variant='body2'>{props.challenge.description}</Typography>}
          action={
            <RequestCardMenu
              challenge={props.challenge}
              refreshCardsHandler={props.refreshCardsHandler}
              refreshUser={props.refreshUser}
              setHidden={setHidden}
            />
          }
        />
        <CardActions>
          <UserAvatar key={`owner_${props.challenge.owner_id}`} user={props.users[props.challenge.owner_id]} ></UserAvatar>
          <ArrowForwardIosIcon />
          {
            props.challenge.recipients.map((i) => (
              <UserAvatar key={i.id} user={props.users[i.id]} ></UserAvatar>
            ))
          }
        </CardActions>
        <CardActions>
          <Typography paragraph>
            <Chip
              className={classes.cChip}
              label={props.challenge.category.title}
              style={{ background: props.challenge.color }}
            />
            <Chip label={props.users[props.challenge.owner_id].username} />
            {
              props.challenge.recipients.map((i) => (
                <Chip label={props.users[i.id].username} />
              ))
            }
          </Typography>
        </CardActions>

        <CardActions>
          {
            props.challenge.state == 'DECLINED' ?
              <RemoveButtons /> : ''
          }
        </CardActions>

      </Card>
    </Paper>
</Grow>
  );
}

function UserAvatar(props) {
  const classes = useStyles();


  return (
    <IconButton key={1} >
      <Avatar src={props.user.gravatar} >{props.user.username}</Avatar>
    </IconButton>
  )
}
const getIconById = (id) => {
  const iconMap = {
    1: <EmojiFoodBeverageIcon></EmojiFoodBeverageIcon>,
    2: <BeachAccessIcon></BeachAccessIcon>,
    3: <ErrorIcon></ErrorIcon>,
    4: <TransferWithinAStationIcon></TransferWithinAStationIcon>,
    5: <LockIcon></LockIcon>,
    7: <EmojiFoodBeverageIcon></EmojiFoodBeverageIcon>,
    8: <BeachAccessIcon></BeachAccessIcon>,
    9: <ErrorIcon></ErrorIcon>,
    10: <TransferWithinAStationIcon></TransferWithinAStationIcon>,
    11: <LockIcon></LockIcon>,
  }
  return iconMap[id]
}
const getIcon = (category) => {
  return getIconById(category.id)
}

export function RequestCardMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelRequest = () => {
    fetch('/api/challenges/' + props.challenge.id, {method: 'delete'})
      .then(handleResponse)
      .then(data => props.setHidden(true))
      .then(data => props.refreshUser())
      .catch((error) => { alert(errorToString(error))})
  }

  return (
    <>
      <IconButton aria-label="more" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="request-card-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={e => { handleMenuClose(e); handleCancelRequest() }}>I changed my mind</MenuItem>
      </Menu>
    </>
  );
}
