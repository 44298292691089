import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Chip, Paper } from '@material-ui/core';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import handleResponse, { errorToString } from './HttpUtils'
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';

export default function Users(props) {
  const [users, setUsers] = React.useState();
  const [games, setGames] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [blockedUsers, setBlockedUsers] = React.useState()
  const activeUser = props.user


  useEffect(() => {
    fetch(`/api/users?game_id=${props.user.active_game}`)
      .then(handleResponse)
      .then(data => setUsers(data))
      .then(refreshGames())
      .then(refreshBlockedUsers())
      .catch(error => alert(errorToString(error)))
  }, [])

  const refreshGames = () => {
    return fetch('/api/games')
      .then(handleResponse)
      .then(data => setGameMapData(data))
      .catch(error => alert(errorToString(error)))
  }

  const refreshBlockedUsers = () => {
    return fetch(`/api/blocks?owner_id=${props.user.id}`)
      .then(handleResponse)
      .then(data => setBlockedUsers(data))
      .catch(error => alert(errorToString(error)))
  }

  const setGameMapData = (games) => {
    const gameMap = (games.reduce(function (map, obj) {
      map[obj.id] = obj
      return map;
    }, {}))
    setGames(gameMap)
  }

  if (!users || !games || !blockedUsers) {
    return <CircularProgress />
  }
  return (
    <>
      {users.map(v => (
        <UserCard key={v.id} user={v} games={games} activeUser={activeUser} blockedUsers={blockedUsers} />
      ))}
    </>
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
  },
  card: {
    marginBottom: "5px"
  },
  imageHeader: {
    height: 180,
    backgroundColor: '#f9f9f9',
    alignItems: 'center',
    textAlign: 'center'
  },
  avatar: {
    height: '150px',
    width: '150px',
    align: 'center',
    margin: 'auto'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  awards: {
    marginRight: '5px',
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  settings: {
    marginLeft: 'auto'
  }
}));

function UserCard(props) {
  const classes = useStyles();

  const get_block_entity = (items) => {
    return (items.find((element) => {
      return element.target_id === props.user.id;
    }))
  }

  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blocked, setBlocked] = React.useState(get_block_entity(props.blockedUsers))

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const isBlocked = (blocked != undefined)

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const BlockUserHandler = (user_id) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'owner': props.activeUser.id, 'target': user_id })
    };
    fetch('/api/blocks', requestOptions)
      .then(handleResponse)
      .then(data => {setBlocked(data)})
      .then(handleMenuClose())
      .catch(error => {alert(errorToString(error))})
  }

  const UnBlockUserHandler = (block) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(`/api/blocks/${block.id}`, requestOptions)
      .then(handleResponse)
      .then(data => setBlocked(undefined))
      .then(handleMenuClose())
      .catch(error => {alert(errorToString(error))})
  }

  const menuId = 'primary-search-account-menu';

const Snapchat = () => {
  if(props.user.snapchat != null && props.user.snapchat != "null" && props.user.snapchat != ""){
    return(<ListItem>
      <ListItemAvatar>
        <Avatar src="/static/pictures/snapchat.png" />
      </ListItemAvatar>
      <ListItem button component="a" href={`https://www.snapchat.com/add/${props.user.snapchat}`}>
          <ListItemText primary={props.user.snapchat} />
            </ListItem>
    </ListItem>)
  } return <></> }

  const Kik = () => {
    if(props.user.kik != null && props.user.kik != "null" && props.user.kik !=""){
      return(<ListItem>
        <ListItemAvatar>
          <Avatar src="/static/pictures/kik.png" />
        </ListItemAvatar>
        <ListItemText primary={props.user.kik} />
      </ListItem>)
    } return <></> }

    const Facebook = () => {
      if(props.user.facebook != null && props.user.facebook != "null" && props.user.facebook !=""){
        return(<ListItem>
          <ListItemAvatar>
            <Avatar src="/static/pictures/facebook.png" />
          </ListItemAvatar>
          <ListItem button component="a" href={`https://facebook.com/${props.user.facebook}`}>
              <ListItemText primary={props.user.facebook} />
                </ListItem>
        </ListItem>)
      } return <></> }

      const Instagram = () => {
        if(props.user.instagram != null && props.user.instagram != "null" && props.user.instagram !=""){
          return(<ListItem>
            <ListItemAvatar>
              <Avatar src="/static/pictures/instagram.jpeg" />
            </ListItemAvatar>
            <ListItem button component="a" href={`https://instagram.com/${props.user.instagram}`}>
              <ListItemText primary={props.user.instagram} />
                </ListItem>
          </ListItem>)
        } return <></> }

        const Darkside = () => {
          if(props.user.darkside != null && props.user.darkside != "null" && props.user.darkside !=""){
            return(<ListItem>
              <ListItemAvatar>
                <Avatar src="/static/pictures/darkside.png" />
              </ListItemAvatar>
              <ListItem button component="a" href={`https://www.darkside.se/profil.php?sokmedlem=${props.user.darkside}`}>
                <ListItemText primary={props.user.darkside} />
              </ListItem>
            </ListItem>)
          } return <></> }

          const Chaturbate = () => {
            if(props.user.chaturbate != null && props.user.chaturbate != "null" && props.user.chaturbate !=""){
              return(<ListItem>
                <ListItemAvatar>
                  <Avatar src="/static/pictures/chaturbate.png" />
                </ListItemAvatar>
                <ListItem button component="a" href={`https://chaturbate.com/${props.user.chaturbate}`}>
                  <ListItemText primary={props.user.chaturbate} />
                </ListItem>
              </ListItem>)
            } return <></> }

            const Webpage = () => {
              if(props.user.webpage != null && props.user.webpage != "null" && props.user.webpage !=""){
                return(<ListItem>
                  <ListItemAvatar>
                    <Avatar src="/static/pictures/webpage.jpg" />
                  </ListItemAvatar>
                  <ListItem button component="a" href={props.user.webpage}>
                    <ListItemText primary={props.user.webpage} />
                      </ListItem>
                </ListItem>)
              } return <></> }



  return (

    <Paper elevation={3} className={classes.card} key={props.user.id}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent className={classes.imageHeader}>
            <Typography align="center">
              <Avatar align="center" src={props.user.gravatar} className={classes.avatar} />
            </Typography>
          </CardContent>
          <CardActions>
          </CardActions>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {props.user.username}
              {isBlocked ?
                 <Chip
                  label={'BLOCKED'}
                  color="secondary"
                  style={{marginLeft: '10px'}}
                />
                :
                <></>
              }
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.user.description}
            </Typography>


          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <IconButton
            aria-label="settings"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            {isBlocked ?
            <MenuItem onClick={e => { handleMenuClose(e); UnBlockUserHandler(blocked) }}>Unblock user</MenuItem>
            :
            <MenuItem onClick={e => { handleMenuClose(e); BlockUserHandler(props.user.id)}}>Block user</MenuItem>
            }
          </Menu>

          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>


        <Collapse in={expanded} timeout="auto" unmountOnExit>

          <Divider />

          <CardContent>
            <ListItemText
              primary="R"
              secondary={props.user.relationship_status}
            />
            <ListItemText
              primary="B"
              secondary={props.user.boundary_status}
            />
            <ListItemText
              primary="D"
              secondary={props.user.desire_status}
            />
            <ListItemText
              primary="S"
              secondary={props.user.sexual_status}
            />
            <ListItemText
              primary="M"
              secondary={props.user.meaning_status}
            />
          </CardContent>

          <Divider />

          <CardContent>
            <ListItemText
              primary="Total points"
              secondary={props.user.points}
            />

            <ListItemText
              primary="Active game"
              secondary={props.games[props.user.active_game].title}
            />

          </CardContent>
          <Divider />

          <CardContent>
            <ListItemText primary="Social media" />
            <Snapchat></Snapchat>
            <Kik></Kik>
            <Facebook></Facebook>
            <Instagram></Instagram>
            <Darkside></Darkside>
            <Chaturbate></Chaturbate>
            <Webpage></Webpage>
          </CardContent>

          <Divider />
          <CardContent>
            <Grid container>
              {props.user.rewards.map(v => (
                <Grid>

                  <Tooltip key={`${props.user.id}${v.title}${v.description}`} title={`${v.title} - ${v.description}`} aria-label={`${v.title} - ${v.description}`} arrow>
                    <Avatar variant="square" src={`/static/pictures/rewards/${v.picture}`} className={classes.awards} />
                  </Tooltip>
                </Grid>
              ))}
            </Grid>

          </CardContent>

        </Collapse>
      </Card>
    </Paper>
  );
}
