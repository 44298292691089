import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import handleResponse, {errorToString} from './HttpUtils'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    width: 300,
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function Leaderboard(props){
  const classes = useStyles();
  const [users, setUsers] = React.useState({});
  const [expandedTp, setExpandedTp] = React.useState(false);
  const handleExpandTp = () => {
    setExpandedTp(!expandedTp);
  };
  const [expandedAwards, setExpandedAwards] = React.useState(false);
  const handleExpandAwards = () => {
    setExpandedAwards(!expandedAwards);
  };
  useEffect(() => {
    refreshUsers()
  }, [])

  const refreshUsers = () => {
    return fetch('/api/users')
      .then(handleResponse)
      .then(data => setUserMapData(data));
  }


  const setUserMapData = (users) => {
    const userMap = (users.reduce(function (map, obj) {
      map[obj.id] = obj
      return map;
    }, {}))
    setUsers(userMap)
  }

  return(

            <div className={classes.paper}>

            <Card className={classes.root}>
            <CardHeader title="Top Pervs" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              Here are the top scores for your active game.
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedTp,
            })}
            onClick={handleExpandTp}
            aria-expanded={expandedTp}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedTp} timeout="auto" unmountOnExit>
            <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              T.B.A.
            </Typography>
            </CardContent>
            </Collapse>
            </Card>

            <Card className={classes.root}>
            <CardHeader title="My Awards" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              Here youcan see all the awards given to you
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedAwards,
            })}
            onClick={handleExpandAwards}
            aria-expanded={expandedAwards}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedAwards} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                T.B.A.
              </Typography>
            </CardContent>
            </Collapse>
            </Card>

            </div>



  )
}
