import React, { useEffect } from 'react';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import LockIcon from '@material-ui/icons/Lock';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import handleResponse, {errorToString} from './HttpUtils'
import MuiAlert from '@material-ui/lab/Alert';
import { CircularProgress, IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  buttonGrid:{
    flexGrow: 1,
  },
  root: {
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial'
  },
  pos: {
    marginBottom: 12,
  },
  userSelected: {
    width: "60px",
    height: "60px",
    border: "2px solid #666"
  },
  user: {
    width: "60px",
    height: "60px",
  },
  shopButton:{
    margin: "10px"
  },
  specGridItem: {
    marginRight: "10px",
    marginBottom: "10px"
  }
});


  export default function Shop2(props){
    const classes = useStyles();
    const [categories, setCategories] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [error, setError] = React.useState('')
    const [limit, setLimit] = React.useState(null)
    const [limitReached, setLimitReached] = React.useState(false)

    useEffect(() => {
      refreshUsers()
        .then(() => refreshCategories())
    }, [])

    useEffect(() => {
      refreshLimit()
    }, [])

    const refreshUsers = () => {
      return fetch('/api/users')
        .then(handleResponse)
        .then(data => setUsers(data));
    }

    const refreshCategories = () => {
      return fetch('/api/categories')
        .then(handleResponse)
        .then(data => setCategories(data));
    }

    const refreshLimit = () => {
    return fetch(`/api/games/${props.user.active_game}/stats?user=${props.user.id}`)
        .then(handleResponse)
        .then(data => {
          const new_limit = data['limits']['hand']
          setLimit(new_limit)
          setLimitReached(new_limit['value']>=new_limit['limit'] ? true : false) 
        }
    );
  }

  const buy = (category) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    return fetch('/api/categories/' + category.id + '/buy', requestOptions)
      .then(handleResponse)
  }

  const buyHandler = (category) => {
    buy(category)
    .then(data => props.refreshUser())
    .then(data => refreshLimit())
    .catch(error => {setError(errorToString(error))})
  }

  if(!limit || !users || !categories){
    return <CircularProgress />
  }

  return (
    <>
      <Typography className={classes.pos} color="textSecondary">
        SHOP (${props.user.pervbucks})
      </Typography>
      {
        limitReached ?
      <MuiAlert  variant="outlined" severity="error">You have {limit['value']}/{limit['limit']} cards in your hand</MuiAlert>
      :
      <Typography color="textSecondary">You have {limit['value']}/{limit['limit']} cards in your hand</Typography>
      }
      <ShopTable limitReached={limitReached} categories={categories} buyHandler={buyHandler} error={error} pervbucks={props.user.pervbucks} />
    </>
  )
}


function ShopTable(props) {
  const classes = useStyles();

  return (
    <Paper elevation={3} >
    {props.error ? <MuiAlert  variant="outlined" severity="error">{props.error}</MuiAlert> : <></>}
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableBody>
          {props.categories.map(row => (
            <ShopItem limitReached={props.limitReached} key={row.id} row={row} buyHandler={props.buyHandler} pervbucks={props.pervbucks} classes={classes} />        
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
  );
}

function ShopItem(props){

  const disabled = props.pervbucks < props.row.cost || props.limitReached
  const row = props.row
  const classes = props.classes

  return (
  <TableRow key={row.id}>
    <TableCell align="left">
      <Typography paragraph>
        <Chip
          className={classes.cChip}
          label={row.title}
          style={{ background: row.color }}
        />
        <Chip icon={<AttachMoneyIcon />}
          label={row.cost}
          className={classes.cChip}
        />
      </Typography>
      <Typography paragraph>
        {row.description}
      </Typography>

    </TableCell>
    <TableCell align="right">
      <Button variant="contained" color="primary" disabled={disabled} onClick={(e) => props.buyHandler(row)}>Buy</Button></TableCell>
  </TableRow>
  )
}