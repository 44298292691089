import React, { useEffect } from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useSwipeable } from "react-swipeable";
import Cards from './Cards'
import Challenges from './Challenges'
import Shop from './Shop'
import Users from './Users'
import Requests from './Requests'
import SwipeableViews from 'react-swipeable-views';
import PersonIcon from '@material-ui/icons/Person';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HelpIcon from '@material-ui/icons/Help';
import ErrorIcon from '@material-ui/icons/Error';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Route, BrowserRouter, Switch, Link } from "react-router-dom";
import SwipeableRoutes from "react-swipeable-routes";
import { useHistory } from "react-router-dom";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export function NavTabs(props) {
  
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const history = useHistory();

 const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
      const location = history.location.pathname.split('/').pop()
      const value = parseInt(location)
      if(!Number.isNaN(value)){
        setValue(value)
      }
    }, [])


  return (
    <div className={classes.root} >

      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <Tab icon={<PersonIcon />} component={Link} to="/game/0" aria-label="pervs" {...a11yProps(0)} />
          <Tab icon={<ErrorIcon />} component={Link} to="/game/1" aria-label="challenges" {...a11yProps(1)} />
          <Tab icon={<HelpIcon />} component={Link} to="/game/2" aria-label="requests" {...a11yProps(2)} />
          <Tab icon={<AddToPhotosIcon />} component={Link} to="/game/3" aria-label="cards" {...a11yProps(3)} />
          <Tab icon={<AttachMoneyIcon />}component={Link} to="/game/4" aria-label="shop" {...a11yProps(4)} />
        </Tabs>
      </AppBar>

    <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <Users user={props.user}></Users>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Challenges user={props.user} game={props.game} refreshUser={props.refreshUser}></Challenges>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Requests user={props.user} game={props.game} refreshUser={props.refreshUser}></Requests>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Cards user={props.user} game={props.game} refreshUser={props.refreshUser}></Cards>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Shop user={props.user} refreshUser={props.refreshUser}></Shop>
        </TabPanel>
      </SwipeableViews>

    </div>
  );
}