import React, { useEffect } from 'react';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgress, IconButton } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Paper } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import handleResponse, {errorToString} from './HttpUtils'
import MuiAlert from '@material-ui/lab/Alert';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles({
  buttonGrid:{
    flexGrow: 1,
  },
  root: {
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial'
  },
  pos: {
    marginBottom: 12,
  },
  userSelected: {
    width: "60px",
    height: "60px",
    border: "2px solid #666"
  },
  user: {
    width: "60px",
    height: "60px",
  },
  shopButton:{
    margin: "10px"
  },
  specGridItem: {
    marginRight: "10px",
    marginBottom: "10px"
  }
});

const useStyles2 = makeStyles((theme) => ({
root: {
    width: 300
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  challengeButton: {
    width: '100%'
  },
  denseHeader:{
    padding: "0px",
  }
}));

export default function Cards(props){
  const classes = useStyles();

  const [challenges, setChallenges] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [limit, setLimit] = React.useState(null)
  const [limitReached, setLimitReached] = React.useState(false)

  useEffect(() => {
      fetch(`/api/users`)
        .then(handleResponse)
        .then(data => setUsers(data))
        .then(() => refreshCards())
    }, [])

  useEffect(() => {
    refreshLimit()
  }, [])


  const refreshCards = () => {
      return fetch(`/api/challenges?grouped=true&state=unused&owner=${props.user.id}&game_id=${props.user.active_game}`)
        .then(handleResponse)
        .then(data => setChallenges(data));
  }

  const refreshLimit = () => {
    return fetch(`/api/games/${props.user.active_game}/stats?user=${props.user.id}`)
        .then(handleResponse)
        .then(data => {
          const new_limit = data['limits']['in_play']
          setLimit(new_limit)
          setLimitReached(new_limit['value']>=new_limit['limit'] ? true : false) 
        }
    );
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if(!limit || !users || !challenges){
    return <CircularProgress />
  }

  return (
    <div>

      <Typography className={classes.pos} color="textSecondary">
        CARDS
      </Typography>
      {
        limitReached ?
          <MuiAlert variant="outlined" severity='error'>You have {limit['value']}/{limit['limit']} cards in play</MuiAlert>
          :
          <Typography color="textSecondary">You have {limit['value']}/{limit['limit']} cards in play</Typography>
      }
      <div className={classes.root}>
      <Grid container>
          {challenges.map(v => (
              <Grid key={v.id} item className={classes.specGridItem}>
                <ChallengeCard
                  challenge={v}
                  users={users}
                  game={props.game}
                  user={props.user}
                  refreshUser={props.refreshUser}
                  refreshCardsHandler={refreshCards}
                  refreshLimit={refreshLimit}
                  handleChange={handleChange}
                  limitReached={limitReached}
                  expanded={expanded}
                />
              </Grid>
          ))}
      </Grid>
      </div>
    </div>
  )
}

function UserAvatar(props) {
  const classes = useStyles();

  const getClass = () => {
    if(props.badge.selected){
      return classes.userSelected
    }
    return classes.user
  }

  return(
    <IconButton key={props.badge.user.identity} onClick={(e) => props.toggleFn(props.badge)}>
      <Avatar src={props.badge.user.gravatar} className={getClass()} title={props.badge.user.username} >{props.badge.user.username}</Avatar>
    </IconButton>
  )
}

function ChallengeCard(props) {

  const classes = useStyles2();

  const badgesInitState = props.users.reduce(function(map, obj) {
      map[obj.id] = {identity: obj.id, user: obj, selected: false};
      return map
  }, {});

  const filterBadgesBySex = (badges) => {

    if (props.challenge.genitalia == 1) {
      const res = Object.fromEntries(Object.entries({ ...badges }).filter(([k, v]) => v.user.genitalia == 'COCK'));
      return res
    }
    if (props.challenge.genitalia == 2) {
      const res = Object.fromEntries(Object.entries({ ...badges }).filter(([k, v]) => v.user.genitalia == 'PUSSY'));
      return res
    }
    return badges
  }

  const filterBadgesByActiveUser = (badges) => {
    return Object.fromEntries(Object.entries({ ...badges }).filter(([k, v]) => v.user.id != props.user.id));
  }

  const filterBadgesByActiveGame = (badges) => {
    return Object.fromEntries(Object.entries({ ...badges }).filter(([k, v]) => props.game.users.includes(v.user.id)));
  }

  const filteredInitialBadgeState = filterBadgesByActiveUser(filterBadgesBySex(filterBadgesByActiveGame(badgesInitState)))

  const [badges, setBadges] = React.useState(filteredInitialBadgeState);
  const [valid, setValid] = React.useState(false);
  const [nbrSelected, setNumberSelected] = React.useState(0);
  const [selectedBadges, setSelectedBadges] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [error, setError] = React.useState('')
  const [hidden, setHidden] = React.useState(false);

  const toggleUserSelection = (badge) => {
    let tmp = {...badges}
    let tmp_badge = {...tmp[badge.identity]}
    tmp_badge.selected = !tmp_badge.selected
    tmp[badge.identity] = tmp_badge
    setBadges(tmp)

    var selected = Object.fromEntries(Object.entries({...tmp}).filter(([k,v]) => v.selected==1));
    setSelectedBadges(selected)

    var nbrSelected = (Object.keys(selected).length)
    setNumberSelected(nbrSelected)

    if (nbrSelected == props.challenge.number_of_participants){
      setValid(true)
    }
    else{
      setValid(false)
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const SendButtonHandler = (e) => {
    e.preventDefault()

    var ids = Object.keys(selectedBadges)
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'participants': ids })
    };
    fetch('/api/challenges/' + props.challenge.id + '/send', requestOptions)
      .then(handleResponse)
      // .then(data => props.refreshCardsHandler())
      .then(data => setHidden(true))
      .then(_ => props.refreshLimit())
      .catch(error => {setError(errorToString(error))})
  }

  const DeleteButtonHandler = (challenge) => {
    const requestOptions = {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api/challenges/' + props.challenge.id, requestOptions)
      .then(response => {
        if(response.ok){
          return response.json()
        }
        throw new Error(response.json());
      })
      // .then(data => props.refreshCardsHandler())
      .then(data => setHidden(true))
      .then(data => props.refreshUser())
      .catch((error) => { alert(error) })
  }


  return (
<Grow in={!hidden} unmountOnExit >
    <Paper elevation={3} className={classes.root} >
      <Card id={`ch-${props.challenge.id}`}>
        <CardHeader
          action={
            <>
              <IconButton aria-label="settings" onClick={(e) => DeleteButtonHandler(props.challenge)}>
                <DeleteIcon />
              </IconButton>
            </>
          }
          title={<Typography variant='h6' className={classes.denseHeader}>{props.challenge.title}</Typography>}
          subheader={<Typography variant='body2'>{props.challenge.description}</Typography>}
        />

        <CardActions disableSpacing>

          <Typography paragraph>
            <Chip
              className={classes.cChip}
              label={props.challenge.category.title}
              style={{ background: props.challenge.color }}
            />
            <Chip
              icon={<PersonIcon />}
              label={props.challenge.number_of_participants}
              className={classes.cChip}
            />

            <Chip icon={<AttachMoneyIcon />}
              label={props.challenge.reward}
              className={classes.cChip}
            />
          </Typography>

          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>

            <Typography paragraph>
              <>
                {
                  Object.entries(badges).map(([key, value]) =>
                    <UserAvatar key={key} badge={value} toggleFn={toggleUserSelection}></UserAvatar>
                  )
                }
              </>
            </Typography>
            <Typography paragraph variant="body2">
              Selected:
              {
                Object.entries(selectedBadges).map(([key, value]) =>
                  <Chip key={key} label={value.user.username} />
                )
              }
            </Typography>

            <Typography paragraph>
              <Button className={classes.challengeButton} variant="contained" color="secondary" disabled={!valid || props.limitReached} onClick={SendButtonHandler}>

                Challenge <br />
            ({nbrSelected} / {props.challenge.number_of_participants})
        </Button>

          {error ? <MuiAlert  variant="outlined" severity="error">{error}</MuiAlert> : <></>}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Paper>
</Grow>
  );
}
