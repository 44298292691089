import './App.css';
import { NavTabs } from './Navigation'
import { Route, BrowserRouter, Switch, Link } from "react-router-dom";
import ButtonAppBar from './TopBar'
import Messages from './Messages'
import { Button, CircularProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import SignIn from './SignIn'
import Profile from './Profile'
import Pervlife from './Pervlife'
import Faq from './Faq'
import Leaderboard from './Leaderboard'
import GamesOverview from './GameManagement'
import Community from './Community'
import Admin from './Admin'
import React, { useEffect } from 'react';
import handleResponse, { errorToString } from './HttpUtils'
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';
import pink from '@material-ui/core/colors/pink';
import { Redirect } from "react-router-dom";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[800]
    },
    secondary: {
      main: pink[900]
    }
  }
});

function App() {

  const [user, setUser] = React.useState(null)
  const [game, setGame] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    refreshUser()
      .catch((error) => {
        console.error(error);
      })
  }, [])


  const refreshUser = () => {
    return fetch('/api/session')
      .then(handleResponse)
      .then(data => { setUser(data); return data })
      .then(data => refreshGame(data))
      .then(_ => setLoaded(true))
      .catch(error => {
        if (error.status != 401) {
          alert(errorToString(error))
        }
        setLoaded(true)
      })
  }

  const refreshGame = (user) => {
    return fetch(`/api/games/${user.active_game}`)
      .then(handleResponse)
      .then(data => setGame(data))
  }

  const logout = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    return fetch('/api/session', requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        return null
      })
      .then(data => { setUser(null) })
  }


  const setLoggedInUser = (user) => {
    setUser(user)
    refreshGame(user)
  }

  const logoutHandler = (e) => {
    logout()
  }

  if (!loaded){
    return <CircularProgress />
  }

  if (!user) {
    return (
      <ThemeProvider theme={theme}>
        <SignIn setUserHandler={setLoggedInUser} ></SignIn>
      </ThemeProvider>
    )
  }

  if (!game){
    return <CircularProgress />
  }

  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/login" >
          <SignIn setUserHandler={setLoggedInUser} ></SignIn>
         </Route>

        <Route path="/messages" >
          <div className="App">
            <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
            <Messages user={user} refreshUser={refreshUser}></Messages>
          </div>
        </Route>

        <Route path="/game" >
          <div className="App">
            <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
            <NavTabs user={user} game={game} refreshUser={refreshUser}></NavTabs>
          </div>
        </Route>

        <Route path="/profile" >
          <div className="App">
            <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
            <Profile user={user} refreshUser={refreshUser} />
          </div>
        </Route>

        <Route path="/pervlife" >
          <div className="App">
            <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
            <Pervlife user={user} refreshUser={refreshUser} />
          </div>
        </Route>

        <Route path="/community" >
          <div className="App">
              <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
              <Community user={user} refreshUser={refreshUser} />
          </div>
          </Route>

          <Route path="/admin" >
            <div className="App">
                <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
                <Admin user={user} refreshUser={refreshUser} />
            </div>

        </Route>
        <Route path="/faq" >
          <div className="App">
            <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
            <Faq user={user} refreshUser={refreshUser} />
          </div>
        </Route>
        <Route path="/leaderboard" >
          <div className="App">
            <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
            <Leaderboard user={user} refreshUser={refreshUser} />
          </div>
        </Route>
        <Route path="/game_management" >
          <div className="App">
            <ButtonAppBar logoutHandler={logoutHandler} user={user} ></ButtonAppBar>
            <GamesOverview user={user} refreshUser={refreshUser} />
          </div>
        </Route>
        <Route path="/" >
          <Redirect to='/game'></Redirect>
        </Route>

      </Switch>
    </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
