import {NavTabs} from './Navigation'
import ButtonAppBar from './TopBar'
import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import SignIn from './SignIn'
import React, { useEffect, Suspense } from 'react';
import handleResponse, {errorToString} from './HttpUtils'
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { IconButton } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';
import {useImage} from 'react-image'


import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import 'moment-timezone';
import ReactTextFormat from 'react-text-format';
import Moment from 'react-moment';

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '95%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  userSelectContainer: {
    marginTop: '10px',
    marginBottom: '10px'
  }
}));

function Messages(props) {

  const classes = useStyles();
  const [conversations, setConversations] = React.useState(null)
  const [cid, setCid] = React.useState()
  const [users, setUsers] = React.useState({});
  const [msg, setMsg] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState();
  const [fileInputKey, setFileInputKey] = React.useState('A');
  const history = useHistory();

    useEffect(() => {
        refreshUsers()
            .then(refreshConversations)
            .catch(error => {
                alert(errorToString(error))
            })

    }, [])


    useEffect(() => {
      const timer = setTimeout(() => {
        refreshConversations()
      }, 5*1000);
      return () => clearTimeout(timer);
    });

    const changeCid = (cid) => {
      setCid(cid)
      history.push(`/messages/${cid}`)
    }

    const setInitialCid = (data) => {
      const location = history.location.pathname.split('/').pop()
      const value = parseInt(location)
      if(!Number.isNaN(value)){
        setCid(value)
        return
      }
      changeCid(data[0].id)
    }

    const refreshConversations = () => {
        return fetch(`/api/messages?user=${props.user.id}`)
            .then(handleResponse)
            .then(data => { setConversations(data); return data })
    }

    const refreshUsers = () => {
        return fetch('/api/users')
            .then(handleResponse)
            .then(data => {setUserMapData(data); return data})
            .then(data => setInitialCid(data))
    }

 const setUserMapData = (users) => {
        const userMap = (users.reduce(function(map, obj) {
            map[obj.id] = obj
            return map;
        }, {}))
        setUsers(userMap)
    }

  const handleChange = (event) => {
    changeCid(event.target.value);
  };

    const fileSelectHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};

  
const DeleteButtonHandler = (message) => {
    const requestOptions = {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api/messages/' + message.id, requestOptions)
    .then(handleResponse)
      .then(data => refreshConversations())
      .catch((error) => { alert(error) })
  }
const SendButtonHandler = (e) => {

    const formData = new FormData();
    if(selectedFile){
        formData.append('file', selectedFile);
    }
    formData.append('body', msg);
    formData.append('recipient', cid);
    formData.append('author', props.user.id);

    const requestOptions = {
      method: 'post',
      body: formData,
    };
    fetch('/api/messages', requestOptions)
    .then(handleResponse)
      .then(data => refreshConversations())
      .then(_ => {
          setMsg('');
          setSelectedFile(null); 
          let randomString = Math.random().toString(36);
          setFileInputKey(randomString)
          return _
        })
      .catch((error) => { alert(error) })
  }

 const handleMsgChange = (event) => {
    setMsg(event.target.value);
  };

  if (!conversations || !users || !cid) {
    return <CircularProgress />
  }
  

  return (
      <Container maxWidth="md">

      <Grid container component={Paper} className={classes.userSelectContainer}>
        <Grid item xs={12} >
            <FormControl className={classes.formControl}>
            <InputLabel id="conversation">User</InputLabel>
            <Select
              labelId="conversation"
              id="conversation"
              value={cid}
              onChange={handleChange}
            >
              {Object.entries(users).map(([key, value]) =>
                <MenuItem key={key} value={key}><UserAvatar user={users[key]} />{users[key].username}</MenuItem>
              )
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>

  <Chat 
    messages={conversations[cid] || []}
    users={users}
    user={props.user}
    cid={cid}
    SendButtonHandler={SendButtonHandler}
    fileInputKey={fileInputKey}
    fileSelectHandler={fileSelectHandler}
    msg={msg}
    handleMsgChange={handleMsgChange}
    />
      </Container>
  );
}

function UserAvatar(props) {
  const classes = useStyles();


  return(
    <IconButton key={1} >
      <Avatar src={props.user.gravatar} >{props.user.username}</Avatar>
    </IconButton>
  )
}

function Img(props) {

  const {src} = useImage({
    srcList: props.url,
  })
 
  return <img src={src} className={props.className} />
}


const chatStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    overflowY: 'auto'
  },
  media: {
    width: "100%",
    maxWidth: '600px' 
  },
  content: {
    whiteSpace: "pre-line",
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto'
  },
  owner: {
    borderLeft: '2px solid darkblue'
  },
  recipient: {
    borderLeft: '2px solid darkred'
  },
  owner_name: {
    paddingLeft: "0px",
    color: 'darkblue',
    fontSize: '12px',
    fontWeight: 'bold'
  },
  recipient_name: {
    paddingLeft: "0px",
    color: 'darkred',
    fontSize: '12px',
    fontWeight: 'bold'
  },
  date:{
    paddingLeft: '10px',
    color: '#666'
  }

});

const Chat = (props) => {
  const classes = chatStyles();

  return (
      <Grid container component={Paper} className={classes.chatSection} >

        <Grid item xs={12}>
          <Grid container style={{ padding: '20px' }}>
            <Grid item xs={11}>
              <TextField
                id="outlined-basic-email"
                label="Type Something"
                value={props.msg} onChange={props.handleMsgChange}
                fullWidth
                multiline
                rows={8}
              />
            </Grid>
            <Grid align="right">
              <Fab color="primary" aria-label="add" onClick={props.SendButtonHandler} ><SendIcon /></Fab>
            </Grid>
            <input type="file" name="file" key={props.fileInputKey || ''} onChange={props.fileSelectHandler} />
          </Grid>
          <Divider />
          <List className={classes.messageArea}>

            {
              props.messages.map((i) => (
                <Message message={i}
                  user={props.user}
                  users={props.users}
                  DeleteButtonHandler={props.DeleteButtonHandler}
                  key={i.id}
                />
              ))
            }
          </List>
        </Grid>
      </Grid>
  );
}

const Message = (props) => {
  const classes = chatStyles();
  const message = props.message
  // const side = message.author_id == props.user.id ? 'right' : 'left'
  const side = 'left'
  const DeleteButtonHandler = props.DeleteButtonHandler

  const getUsername = (id) => {
    try{
      return props.users[id].username
    }
    catch(err){
      return 'Unknown user'
    }
  }

  return (
    <>
    <ListItem className={message.author_id == props.user.id ? classes.owner_name: classes.recipient_name} id={`msg-${message.id}-title`}>
      <span style={{ textTransform: 'uppercase'}}>
      {getUsername(message.author_id)}
      </span>
      <span className={classes.date}>

            <Moment fromNow>
              {message.created+'.1Z'}
            </Moment>
  </span>
    </ListItem>
      <ListItem className={message.author_id == props.user.id ? classes.owner: classes.recipient} id={`msg-${message.id}`}>
        <Grid container>
          <Grid item xs={12}>
            <ListItemText className={classes.content} align={side} primary={
            <>
                <ReactTextFormat>
                  {message.body}
                </ReactTextFormat>
            <br/><FileViewer message={message} />
            </>} />
          </Grid>
        </Grid>
      </ListItem>
                  
      {/* <IconButton aria-label="settings" onClick={(e) => DeleteButtonHandler(message)}>
        <DeleteIcon />
      </IconButton>
 */}
    </>
  )
}
const FileViewer = (props) => {
      const classes = chatStyles();

      if (props.message.type=='IMAGE') {
          return (
              <>
              <Suspense fallback={<CircularProgress></CircularProgress>}>
                <Img url={props.message.path} className={classes.media} />
              </Suspense>
              </>
          )
      }
      if (props.message.type=='VIDEO') {
          return (
              <>
                  <video controls className={classes.media}>
                      <source src={props.message.path} type={props.message.mime}></source>
                  </video>
              </>
          )
      }
      return <></>
  }
export default Messages;


