import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import handleResponse, {errorToString} from './HttpUtils'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    width: 300,
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function Faq(props){
  const classes = useStyles();

  const [expandedQ1, setExpandedQ1] = React.useState(false);
  const handleExpandQ1 = () => {
    setExpandedQ1(!expandedQ1);
  };

  const [expandedQ2, setExpandedQ2] = React.useState(false);
  const handleExpandQ2 = () => {
    setExpandedQ2(!expandedQ2);
  };

  const [expandedQ3, setExpandedQ3] = React.useState(false);
  const handleExpandQ3 = () => {
    setExpandedQ3(!expandedQ3);
  };
  const [expandedQ4, setExpandedQ4] = React.useState(false);
  const handleExpandQ4 = () => {
    setExpandedQ4(!expandedQ4);
  };
  const [expandedQ5, setExpandedQ5] = React.useState(false);
  const handleExpandQ5 = () => {
    setExpandedQ5(!expandedQ5);
  };
  return(

            <div className={classes.paper}>
            <Card className={classes.root}>
            <CardHeader title="How do I play?" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              So how does one actually play the game?
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedQ1,
            })}
            onClick={handleExpandQ1}
            aria-expanded={expandedQ1}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedQ1} timeout="auto" unmountOnExit>
            <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
            Buy a card in the shop by first clicking on the $ sign. When you
            start you have 4 PervBucks to shop for, and you will get more as you
            send and complete challenges<br />
            The card will then appear under [+] in the menu where you can klick
            on the little down arrow in the right corner and choose who to challenge.
            After you send the challenge you find it under the [?] in the menu first
            as a pending challenge and then it moves up after they consent.<br />
            Challenges you receive appear under the [!] in the menu. You will first
            have to consent to the challenge and then when you click complete
            challenge you will get a message window where you can reply with text or
            picture proof. <br />
            Finally you can check the little letter under the menu to see your messages
            sent and received by you and other active players.
            </Typography>
            </CardContent>
            </Collapse>
            </Card>

            <Card className={classes.root}>
            <CardHeader title="Notifications" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              How to get notifications to work?
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedQ2,
            })}
            onClick={handleExpandQ2}
            aria-expanded={expandedQ2}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedQ2} timeout="auto" unmountOnExit>
            <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              If you are on Android the in app notifications work just answer allow
              when asked if you want notifications. <br />
              If you are on iPhone you will need to download and install the
              pushover app from the app store. After you install the app it will give
              you a private key that you can insert in the pushover field in your
              profile. This will give you notifications when something happens in the game.
            </Typography>
            </CardContent>
            </Collapse>
            </Card>
            <Card className={classes.root}>
            <CardHeader title="rBDSM" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              What does it stand for and how do I do it?
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedQ5,
            })}
            onClick={handleExpandQ5}
            aria-expanded={expandedQ5}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedQ5} timeout="auto" unmountOnExit>
            <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              rBDSM is a communication protocol used by the <a href="https://ista.life">ISTA.life</a> community
              to communicate clearly and efficiantly around sexual encounters.
              It is a good conversation to have in any situation where clothes come of (even if it is just online)<br />
              <b>r - Relationship </b> This is for communicating your relationship status and or relationship agreements,
              it can also be good to talk about your gender and sexual preferences. <br />
              <b>B - Boundaries </b> Here we share what our hard boundaries are (things I will never want to do or to happen)
              but also soft boundaries (this is off the table for now, I may change my mind later). We do recognise that boundaries
              change with context and company so it is important to have this conversation as often as possible< br />
              <b>D - Desires </b> Here we share what it is we want, the more specific you can be the higher chance you will
              get consent and experience what you desire. Do not downplay your desires or share what you
              think the other person will say yes to. Chanses are they will feel that you are not being honest
              and you will come of as a creep.<br />
              <b>S - Safer Sex practice</b> Sharing your sexual history and also
              when where you tested, for what where you tested, what where the results
              and what have you done since. It can also be good to share what your practice is
              when it comes to condoms, dental dams and gloves and finally contraception. <br />
              <b>M - Meaning and aftercare </b> What does it mean to you to connect. Are you looking for a
              relationship or just a good time? What are your expectations of connection afterwards? Do you have any
              worries or fears about connecting?
              <br />
              <br />
              Having this conversation will help clean up your connections so that
              everyone involved knows what's what. This is why we have it on our profile
              as a startingpoint for communication around sexuality and sexual adventures.

            </Typography>
            </CardContent>
            </Collapse>
            </Card>
            <Card className={classes.root}>
            <CardHeader title="Points and PervBucks" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              What is the difference between points and pervbucks, when do I get
              them and why do I need them?
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedQ3,
            })}
            onClick={handleExpandQ3}
            aria-expanded={expandedQ3}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedQ3} timeout="auto" unmountOnExit>
            <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              PervBucks is the game currency you can buy cards for them and in the future
              there will also be other perks that can be bought with your pervbucks. <br />
              Points are the games scoring system you will just get more and they cannot be
              spent or used in any way. It's a way of showing who's been playing the game<br />
              You get pervbucks and points every time you complete a challenge or
              when someone completes a challenge you sent. You also get them when
              you decline a challenge. When you discard a card or change your mind
              you get pervbucks back but not points.
            </Typography>
            </CardContent>
            </Collapse>
            </Card>



            <Card className={classes.root}>
            <CardHeader title="Who pays for the game?" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              How is this a free game and who is paying?
              </Typography>
            </CardContent>
            <CardActions>
            <IconButton className={clsx(classes.expand, {
              [classes.expandOpen]: expandedQ4,
            })}
            onClick={handleExpandQ4}
            aria-expanded={expandedQ4}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expandedQ4} timeout="auto" unmountOnExit>
            <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
            Right now this is paid for by Patrik and Anna, all costs from hosting to
            domain registration. The development is kindly donated by Patrik, Lux and
            LillaMy who are coding in their free time to get this game launched. <br />
            In the future you will be able to buy VIP status to help support the project<br />
            If you like this project and want to support our work already now
            pls do so by <a href="https://www.buymeacoffee.com/olterman">Buying me a coffe!</a> Any
            money that comes in will be put towards securing a domain and webhosting for
            this site
            </Typography>
            </CardContent>
            </Collapse>
            </Card>
            </div>



  )
}
