import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Users from './Users';
import MuiAlert from '@material-ui/lab/Alert';
import handleResponse, {errorToString} from './HttpUtils'
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader'
import { IconButton } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Avatar from '@material-ui/core/Avatar';
import { Paper } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import { green, pink } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
    expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  paperWrap: {
      marginTop: '5px'
  },

    formControl: {
        width: '100%'
    },

    activeGameAvatar: {
        backgroundColor: green[500],
    }
}));

function GamesOverview(props){
    const classes = useStyles();

    const [games, setGames] = React.useState();
    const [users, setUsers] = React.useState();

    useEffect(() => {
        refreshGames()
    }, [])

    const getUserById = (id) => {
        return users.find(obj => obj.id == id);
    }

    const addGame = (game) => {
        setGames([...games, game])
    }

    const refreshGames = () => {
        fetch(`/api/games?user=${props.user.id}`)
        .then(handleResponse)
        .then(data => setGames(data))
        .then(_ => fetch('/api/users'))
        .then(handleResponse)
        .then(data => setUsers(data))
        .catch(error => { alert(errorToString(error)) })
    }

    if(!games || !users){
        return <CircularProgress />
    }

    const canCreate = props.user.user_level >= 3

    return (
        <>
            <Container component="main" maxWidth="xs">
                {
                    games.map(game => (
                        <Game key={game.id}
                        game={game}
                        user={props.user}
                        users={users}
                        getUserById={getUserById}
                        classes={classes}
                        refreshGames={refreshGames}
                        refreshUser={props.refreshUser}
                        />
                    ))
                }
                {canCreate && <AddGameForm classes={classes} addGame={addGame} />}
            </Container>
        </>
    )
}

function UserListItem(props){
    const user_id = props.user_id
    const user = props.getUserById(user_id)
    var avatar
    var username

    if (user){
        var avatar = user.gravatar
        var username = user.username
        return (

                <Grid>
                  <Tooltip key={username} title={username} aria-label={username} arrow>
                    <Avatar alt={username} src={avatar}></Avatar>
                    </Tooltip>
                </Grid>
        );
    } else {
      return (<></>);
    }




}

function Game(props){

    const [game, setGame] = React.useState(props.game);
    const classes = props.classes
    const users = props.users

    const icon = game.id == props.user.active_game
    const isActiveGame = game.id == props.user.active_game


    const addUserToGame = (id) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'user_id': id })
        };

        fetch(`/api/games/${game.id}/users`, requestOptions)
        .then(handleResponse)
        .then(_ => fetch(`/api/games/${game.id}`))
        .then(handleResponse)
        .then(data => setGame(data))
        .catch(error => {alert(errorToString(error)) })
    }

    const isActiveUserInGame = game.users.includes(props.user.id)
    const isOwner = game.game_owner_id  == props.user.id

    return (
        <Paper elevation={2} className={classes.paperWrap}>
            <Card >
                <CardHeader
                    avatar={
                        <Avatar className={isActiveGame ? classes.activeGameAvatar: null}>
                            {isActiveGame ? <CheckIcon /> : <ArrowForwardIosIcon />}
                        </Avatar>
                    }
                    action={
                        <GameActionMenu game={game} refreshUser={props.refreshUser} refreshGames={props.refreshGames}
                        isActiveUserInGame={isActiveUserInGame}
                        isOwner={isOwner}
                        />
                    }
                    title={game.title}
                    subheader={game.description}
                />
                <CardContent>

                    <Grid container >
                        {game.users.map((value) => {
                            const labelId = `game_list_users-${game.id}-${value}`;
                            return <UserListItem key={value} user_id={value} getUserById={props.getUserById} labelId={labelId} />
                        })}
                    </Grid>
                    {isOwner &&
                        <AddUserToGameForm game={game} users={users} classes={classes} addUserToGame={addUserToGame} />
                    }

                </CardContent>
            </Card>
        </Paper>
    )
}

function AddUserToGameForm(props){
    const classes = props.classes
    const users = props.users
    const game = props.game

    const [selected, setSelected] = React.useState();

    const addButtonHandler = (e) => {
        props.addUserToGame(selected)
        setSelected([])
    }

    return (
        <>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">User</InputLabel>
                <Select
                    native
                    value={selected}
                    onChange={e => setSelected(e.target.value)}
                    label="User"
                >

                    <option aria-label="None" value="" />
                    {
                        users.map((u) => (
                            <option key={u.id} value={u.id}>{u.username}</option>
                        ))
                    }

                </Select>
            </FormControl>

            <form className={classes.form} noValidate>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={addButtonHandler}
                >
                    Add user
                </Button>
            </form>
        </>
    )
}

function GameActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const activateGameHandler = (e) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(`/api/games/${props.game.id}/activate`, requestOptions)
            .then(handleResponse)
            .then(data => { props.refreshUser() })
            .catch(error => { alert(errorToString(error)) })
    }
    const WipeGameHandler = (e) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(`/api/games/${props.game.id}/wipe`, requestOptions)
            .then(handleResponse)
            .catch(error => { alert(errorToString(error)) })
    }

    return (
        <>
            <IconButton aria-label="more" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="request-card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {props.isActiveUserInGame && <MenuItem onClick={e => { handleMenuClose(e); activateGameHandler(e) }}>Activate</MenuItem>}
                {props.isOwner && <MenuItem onClick={e => { handleMenuClose(e); WipeGameHandler(e)}}>Wipe</MenuItem>}
            </Menu>
        </>
    );
}


function AddGameForm(props) {
  const classes = props.classes
  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState();
  const [gameType, setGameType] = React.useState();
  const [error, setError] = React.useState();

  const addGameHandler = (e) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'title': title, 'description': description, 'game_type': gameType })
        };

        fetch(`/api/games`, requestOptions)
        .then(handleResponse)
        .then(data => {setError(null); return data})
        .then(data => {console.info(data); props.addGame(data)})
        .catch(error => {setError(errorToString(error)) })
    }

  return (
        <Paper elevation={2} className={classes.paperWrap}>
            <Card >
                <CardHeader
                    title="Create new game"
                />
                <CardContent>

                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            autoFocus
                        value={title}
                        onInput={e => setTitle(e.target.value)}
                        />
                      <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                            autoFocus
                        value={description}
                        onInput={e => setDescription(e.target.value)}
                        />
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
                        <Select
                            native
                            fullWidth
                            value={gameType}
                            onChange={e => setGameType(e.target.value)}
                            label="User"
                        >
                            <option aria-label="None" value="" />
                            <option value={'irl'}>IRL Game</option>
                            <option value={'online'}>Online game</option>
                        </Select>
                    </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={addGameHandler}
                        >
                            Create game
          </Button>
                        {error ? <MuiAlert  variant="outlined" severity="error">{error}</MuiAlert> : <></>}

                    </form>
                </CardContent>
            </Card>
        </Paper>
    )

}

export default GamesOverview
