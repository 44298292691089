
export default function handleResponse(response) {
    return response.json()
        .then((json) => {
            if (!response.ok) {
                const error = Object.assign({}, json, {
                    status: response.status,
                    statusText: response.statusText,
                    json: json
                });
                console.error(error)

                return Promise.reject(error);
            }
            return json;
        });
}

export function errorToString(error){
    console.error(error)

    if (error.json) {
        if (error.json.message){
            return error.json.message
        }
        else if (error.json.text){
            return error.json.text
        }
    }

    else {
        return String(error)
    }
}