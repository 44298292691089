import React, { useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Avatar } from '@material-ui/core';
import usePushNotifications from "./usePushNotifications";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
      display: 'block',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },

}));

export default function ButtonAppBar(props) {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const {
    userConsent,
    pushNotificationSupported,
    userSubscription,
    onClickAskUserPermission,
    onClickSusbribeToPushNotification,
    onClickSendSubscriptionToPushServer,
    pushServerSubscriptionId,
    onClickSendNotification,
    enableNotifications,
    error,
    loading
  } = usePushNotifications();

  useEffect(() => {
    enableNotifications()
  }, []);

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={e => {handleMenuClose(e); history.push('/pervlife')}}>Pervlife</MenuItem>
      <MenuItem onClick={e => {handleMenuClose(e); history.push('/faq')}}>F.A.Q.</MenuItem>
      <MenuItem onClick={e => {handleMenuClose(e); history.push('/leaderboard')}}>Leaderboard</MenuItem>
      <MenuItem onClick={e => {handleMenuClose(e); history.push('/profile')}}>Profile</MenuItem>
      <MenuItem onClick={e => {handleMenuClose(e); history.push('/game_management')}}>My games</MenuItem>
      <MenuItem onClick={props.logoutHandler}>Logout</MenuItem>
    </Menu>
  );
  
  const AdminButton = () => {
    if(props.user.user_level == 5){
      return(
        <IconButton aria-label="" color="inherit" onClick={e => history.push('/admin')}>
              <SettingsIcon />
        </IconButton>)
    } return <></>
  }

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            PervLife β
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>

          <AdminButton></AdminButton>

          <IconButton aria-label="" color="inherit" onClick={e => history.push('/community')}>
              <PeopleIcon />
          </IconButton>
            <IconButton aria-label="" color="inherit" onClick={e => history.push('/game')}>
                <SportsEsportsIcon />
            </IconButton>
            <IconButton aria-label="" color="inherit" onClick={e => history.push('/messages')}>
                <MailIcon />
            </IconButton>


            {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}
